import React, { Fragment, useEffect } from "react";
import Benifits from "../../components/Benifits/Benifits";
import Compensation from "../../components/Compensation";
import InjuryClaims from "../../components/InjuryClaims";
import Deserve from "../../components/Deserve";
import Speak from "../../components/Speak";
import ClientRev from "../../components/ClientRev";
import Banner from "../../components/Banner/Banner";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import Faq from "../../components/Faq/Faq";
const HomePage = () => {
  useEffect(() => {
    const getUTMParams = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const utmParams = [];
      searchParams.forEach((value, key) => {
        utmParams.push({ [key]: value });
      });
      localStorage.setItem("utmParams", JSON.stringify(utmParams));
    };
    getUTMParams();
  }, []);

  return (
    <Fragment>
      <Banner />
      <Benifits />
      <HowItWorks />
      <Deserve />
      <InjuryClaims />
      <Speak />
      <ClientRev />
      <Faq />
      <Compensation />
    </Fragment>
  );
};

export default HomePage;
