import React from 'react'
import "./notfound.scss";
const NotFound = () => {
    return (
        <h2 className='notFound'>
            This Page is not found.
        </h2>
    )
}

export default NotFound
