import React from 'react'
import "./copyright.scss";
const CopyRightSection = () => {
  return (
    <div className='copyRight'>
      © Copyright Claim Solutions Scotland Ltd
    </div>
  )
}

export default CopyRightSection
