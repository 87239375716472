import Injury1 from "../assests/images/injury-1.png";
import Injury2 from "../assests/images/injury-2.png";
import Injury3 from "../assests/images/injury-3.png";
import Injury4 from "../assests/images/injury-4.png";
const InjuryClaimData = [
  {
    id: 1,
    title: "Public liability",
    desc: "An accident that occurs in a public place i.e. tripping over uneven pavement on a public footpath",
    img: Injury1,
  },
  {
    id: 2,
    title: "Occupiers liability",
    desc: "An accident in a private space i.e. injuring yourself in a rented property if proper maintenance work has not been carried out",
    img: Injury2,
  },
  {
    id: 3,
    title: "Employers liability",
    desc: "Employers liability – an accident or illness in the work place i.e. falling from height if proper safety equipment or training was not provided",
    img: Injury3,
  },
  {
    id: 4,
    title: "Road traffic accident",
    desc: "An accident that occurs on the road i.e. being knocked off your bicycle by another vehicle",
    img: Injury4,
  },
];
export { InjuryClaimData };
