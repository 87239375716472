import React, { Fragment } from "react";
import "./form.scss";
import FiveStar from "../../assests/images/five-star.svg";
import TrustPilot from "../../assests/images/trustpilot.svg";

import Que1 from "./Que1";
const Form = () => {
  return (
    <Fragment>
      <div className="form">
        <div className="container989">
          <div className="banner__details-rating">
            <span>
              <strong>Excellent</strong>
            </span>
            <span>
              <img src={FiveStar} alt="" />
            </span>
            <span>
              <strong>1,135+</strong> Reviews On
            </span>
            <span>
              <img src={TrustPilot} alt="" />
            </span>
          </div>
          <h2 className="form-title">
            See If You Qualify
            <span className="s1">
              {" "}
              For A Free Personal Injury Claim Assessment
            </span>
            <span className="s2"> In 30 Seconds</span>
          </h2>
          <Que1 />
        </div>
      </div>
    </Fragment>
  );
};

export default Form;
