import React from "react";
import "./HowItWorks.scss";
import BgRing from "../../assests/images/banner-bg-ring.svg";
import TakeQuiz from "../../assests/images/quiz.svg";
import SearchDocs from "../../assests/images/search-docs.png";
import ClaimCompensation from "../../assests/images/claim-compensation.png";
const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <div className="container1286">
        <div>
          <div className="how-it-works__details">
            <h1>
              Here is How <strong>it works</strong>
            </h1>
            <p>
              Within a few minutes we’ll assess your eligibility for
              compensation and start our work.
            </p>
          </div>
          <figure className="how-it-works__ring">
            <img src={BgRing} alt="" srcSet="" />
          </figure>
        </div>

        <div className="how-it-works__steps">
          <div className="how-it-works__steps-one">
            <div className="how-it-works__steps-one-round">
              <figure>
                <img src={TakeQuiz} alt="" loading="lazy" />
              </figure>
              <div className="digit">1</div>
            </div>
            <div className="how-it-works__steps-one-details">
              <h2>Take a quiz</h2>
              <p>Take a quick 30-second quiz to evaluate if you qualify</p>
            </div>
          </div>
          <div className="how-it-works__steps-one">
            <div className="how-it-works__steps-one-round">
              <figure>
                <img src={SearchDocs} alt="" loading="lazy" />
              </figure>
              <div className="digit">2</div>
            </div>
            <div className="how-it-works__steps-one-details">
              <h2>We’ll assess the details</h2>
              <p>
                Our team will give you a call within minutes to discuss further
                details with you
              </p>
            </div>
          </div>
          <div className="how-it-works__steps-one">
            <div className="how-it-works__steps-one-round">
              <figure>
                <img src={ClaimCompensation} alt="" loading="lazy" />
              </figure>
              <div className="digit">3</div>
            </div>
            <div className="how-it-works__steps-one-details">
              <h2>Claim your compensation</h2>
              <p>Lean back while we fight the case for you. </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
