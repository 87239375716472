import React, { useState, useEffect, useRef } from "react";
import "./Faq.scss";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    contentRefs.current.forEach((content, i) => {
      if (content) {
        if (i === activeIndex) {
          content.style.maxHeight = `${content.scrollHeight}px`; // Expand to full height
        } else {
          content.style.maxHeight = "0"; // Collapse smoothly
        }
      }
    });
  }, [activeIndex]);

  const accordionData = [
    {
      question: "What is Personal Injury Claim?",
      answer:
        "A personal injury claim is a legal request for compensation by someone injured due to another's negligence or unsafe conditions. It may cover medical bills or lost wages. Common examples include car accidents, workplace injuries, and medical negligence.",
    },
    {
      question: "Can I Claim?",
      answer:
        "Yes, if you were injured in a car accident or a public or private place and it wasn’t your fault, you may be eligible to make a claim. Our panel of personal injury solicitors will carefully review the details of your case to determine your eligibility.",
    },
    {
      question: "What's the process?",
      answer:
        "Simply fill out a quick form ensuring you have evidence to support your claim. We’ll match you with a claims specialist who will assess your case, offer advice on your accident, and ensure you receive a fair financial settlement.",
    },
    {
      question: "Why Claim Solutions Scotland?",
      answer:
        "At Claim Solutions Scotland, YOU come first. We take the stress off you and connect you with top-notch advice and services. With offices across Scotland, help is just a click or call away.",
    },
    {
      question: "What is the fee for your service?",
      answer:
        "Our services are provided on a *No win, no fee policy. This means that if your claim is unsuccessful you will not pay anything for our services. We shoulder the risk of your claim so you can focus on getting back to living your life.",
      // description:
      //   "*Typically, customers pay 25% including VAT of the compensation amount that is recovered by our third-party law firms, although this is subject to your individual circumstances and the actual fee may be more or less than this. Termination fees may apply if you do not keep to the terms of the agreement.",
    },
  ];

  return (
    <section className="faq">
      <div className="container1286">
        <div className="faq-accordion">
          <div className="faq-accordion-details">
            <h2>
              {" "}
              Frequently Asked <strong>Questions</strong>{" "}
            </h2>
            <p>Need more info? look no further.</p>
          </div>
          {accordionData.map((item, index) => (
            <div
              className={`accordion-item ${
                activeIndex === index ? "active" : ""
              }`}
              key={index}
            >
              <div
                className="accordion-label"
                onClick={() => toggleAccordion(index)}
              >
                <span className="accordion-question">{item.question}</span>
                <span className="icon">
                  <div className="plus-icon">
                    <div className="bar-one"></div>
                    <div className="bar-two"></div>
                  </div>
                </span>
              </div>
              <div
                className="accordion-content"
                ref={(el) => (contentRefs.current[index] = el)}
                style={{
                  overflow: "hidden",
                  transition: "max-height 0.5s ease",
                }}
              >
                <p>{item.answer}</p>
                <br />
                {/* {accordionData.length - 1 === index && (
                  <div>
                    <p className="tnc">
                      <i>{item.description}</i>
                    </p>
                    <br />
                  </div>
                )} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
