import React, { Fragment } from 'react'
import "./benifits.scss";
import { BenifitData } from '../../utils/BenifitsData';

const Benifits = () => {
    return (
        <Fragment>
            <div className='benifits '>
                <div className='container1500'>
                    {
                        BenifitData?.map((data) => {
                            return (
                                <div className='mainType' key={data?.id}>
                                    <div className='type' >
                                        <div className='left'>
                                            <img src={data?.image} alt="img" />
                                        </div>
                                        <div className='right'>
                                            <div className='data'>
                                                <h6>
                                                    {data?.title}
                                                </h6>
                                                <p>
                                                    {data?.desc}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={`border ${data?.id === BenifitData?.length - 0 ? 'hidden' : ''}`}></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Benifits
