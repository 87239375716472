import React, { Fragment } from "react";
import "./footer.scss";
import logo from "../../assests/images/Logo.webp";
import useUTMParams from "../../hooks/utmParams";
import { Link } from "react-router-dom";
const Footer = () => {
  const utmParams = useUTMParams();
  return (
    <Fragment>
      <div className="footer">
        <div className="container1500">
          <div className="footer-info">
            <Link to={`/${utmParams}`} onClick={() => window.scrollTo(0, 0)}>
              <img className="logo" src={logo} alt="logo" />
            </Link>
            <div className="policies">
              <p>
                <a
                  href="https://www.claim-solutions-scotland.co.uk/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
              <p>
                <a
                  href="https://www.claim-solutions-scotland.co.uk/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
              </p>
              <p>
                <a
                  href="https://www.claim-solutions-scotland.co.uk/complaints/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Complaint Procedure
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-info-mob">
        <div className="mob-link">
          <span>
            <a
              href="https://www.claim-solutions-scotland.co.uk/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </span>
          <span>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</span>
          <span>
            <a
              href="https://www.claim-solutions-scotland.co.uk/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </span>
          <span>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</span>

          <span>
            <a
              href="https://www.claim-solutions-scotland.co.uk/complaints/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Complaint Procedure
            </a>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
