import React, { Fragment } from "react";
import "./header.scss";
import logo from "../../../src/assests/images/Logo.webp";
import MCAFreeImg from "../../assests/images/mca-free.png";
import SecureGlobalSignImg from "../../assests/images/secure-global-sign.png";
import InfoStrip from "./InfoStrip";
import { Link } from "react-router-dom";
import useUTMParams from "../../hooks/utmParams";
const Header = () => {
  const utmParams = useUTMParams();
  return (
    <Fragment>
      <div className="header">
        <div className="container1500">
          <div className="header-info">
            <Link to={`/${utmParams}`}>
              <img className="logo" src={logo} alt="logo" />
            </Link>
            <div className="right">
              <img className="mcaFree" src={MCAFreeImg} alt="mca-free" />
              <img
                className="globalSign"
                src={SecureGlobalSignImg}
                alt="global-sign"
              />
            </div>
          </div>
        </div>
      </div>
      <InfoStrip />
    </Fragment>
  );
};

export default Header;
