import React from 'react';
import "./review.scss"
import renderStarImages from '../../hooks/RenderStar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import checkImg from "../../assests/images/greenCheck.svg";
const Review = ({
    userImage,
    star,
    strong,
    desc,
    userName
}) => {
    return (
        <div className="review">
            <div className='container'>
                <div className="review-image">
                    <LazyLoadImage
                        src={userImage}
                        alt=""
                        srcSet=""
                        width={91}
                        height={91}
                    />
                </div>
                <div className="review-details">
                    {renderStarImages(star)}
                    <p>
                        <strong> “{strong}</strong> {desc} ”
                    </p>
                    <span>
                        <span className='userName'>{userName}</span>
                        <LazyLoadImage
                            className='check'
                            src={checkImg}
                            alt=""
                            width={19}
                            height={19}
                        />
                        <span className='RevVerifiedCus'>Verified customer</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Review
