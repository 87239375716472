import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import HomePage from "../pages/HomePage";
import FormPage from "../pages/FormPage";
import Success from "../components/Form/Success";
import NotFound from "../components/NotFound";
import Sorry from "../components/Form/Sorry";
export const Routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "form",
        children: [
          {
            path: "",
            element: <FormPage />,
          },
          {
            path: "success",
            element: <Success />,
          },
          {
            path: "sorry",
            element: <Sorry />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
