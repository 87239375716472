import React, { Fragment } from 'react'
import "./injuryClaims.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { InjuryClaimData } from '../../utils/InjuryClaimData';
const InjuryClaims = () => {
    return (
        <Fragment>
            <div className='injuryClaims'>
                <div className="container1500">
                    <h2 className='injuryClaims-title'>
                        Personal <span>Injury Claims</span>
                    </h2>
                    <p className='injuryClaims-subTitle'>
                        Personal injury and accident claims usually fall into one of the following categories
                    </p>
                    <div className='injuryClaims-types'>
                        {
                            InjuryClaimData?.map((data) => {
                                return (
                                    <div className='type' key={data?.id} >
                                        <div className='tp'>
                                            <LazyLoadImage
                                                className='img'
                                                src={data?.img}
                                                alt='type'
                                            />
                                            <div className='info'>
                                                <h4>{data?.title}</h4>
                                                <p>
                                                    {data?.desc}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default InjuryClaims
