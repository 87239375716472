import React, { useEffect, useState } from "react";
import "./Banner.scss";
import BgRing from "../../assests/images/banner-bg-ring.svg";
import FiveStar from "../../assests/images/five-star.svg";
import TrustPilot from "../../assests/images/trustpilot.svg";
import BlackCheckMark from "../../assests/images/black-checkmark.svg";
import ReviewerOne from "../../assests/images/home-review-1.png";
import PadLock from "../../assests/images/padlock.svg";
import GreenCheckMark from "../../assests/images/green-check-mark.svg";
import { useNavigate } from "react-router";
const Banner = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (text) => {
    setActiveButton(text);
  };
  useEffect(() => {
    if (activeButton !== null) {
      const timer = setTimeout(() => {
        navigate("/form");
        localStorage.setItem("selectedButtonText", activeButton);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [activeButton, navigate]);
  return (
    <div className="b_body">
      <section className="banner container1286">
        <figure className="banner__ring">
          <img src={BgRing} alt="" srcSet="" />
        </figure>
        <div className="wrapper ">
          <div className="banner__details">
            <div className="banner__details-rating">
              <span>
                <strong>Excellent</strong>
              </span>
              <span>
                <img src={FiveStar} alt="" />
              </span>
              <span>
                <strong>1,135+</strong> Reviews On
              </span>
              <span>
                <img src={TrustPilot} alt="" />
              </span>
            </div>
            <div className="banner__details-header">
              <h1>
                <span className="banner-different">See If You Qualify</span> For
                a <span className="break">Free Personal Injury Claim</span>
                <span className="break">
                  {" "}
                  Assessment{" "}
                  <span className="n2 banner-different ">In 30 Seconds</span>
                </span>
              </h1>
            </div>
            <div className="banner__details-list">
              <li>
                <figure>
                  <img src={BlackCheckMark} alt="" />
                </figure>
                <div className="listPoints">
                  Minor injuries, both{" "}
                  <strong>physical and psychological</strong>, may qualify.
                </div>
              </li>
              <li>
                <figure>
                  <img src={BlackCheckMark} alt="" />
                </figure>
                <div>
                  Complete confidentiality{" "}
                  <strong>—your job is never at risk.</strong>
                </div>
              </li>
              <li>
                <figure>
                  <img src={BlackCheckMark} alt="" />
                </figure>
                <div>
                  <strong>Pay nothing&nbsp;</strong> for our service unless we
                  win your case.
                </div>
              </li>
            </div>
            <div className="banner__details-review desk-show">
              <div className="review-image">
                <img
                  src={ReviewerOne}
                  alt=""
                  srcSet=""
                  width={91}
                  height={91}
                />
              </div>
              <div className="review-details">
                <img src={FiveStar} alt="" srcSet="" />
                <p>
                  <strong> “Excellent! Very good</strong> manners and explained
                  how the situation would work. Took time and was not hurried.
                  Always asked if I needed time and or help during
                  conversation.”
                </p>
                <span>
                  <span>Janet</span>
                  <img src={GreenCheckMark} alt="" width={19} height={19} />
                  <span>Verified customer</span>
                </span>
              </div>
            </div>
          </div>
          <div className="banner__card">
            <h2>How were you injured?</h2>
            <p>
              This includes any minor accident that resulted in a physical
              injury
            </p>
            <div className="banner__card-buttons">
              {[
                "Accident at work",
                "Accident in a public place",
                "Accident on a private place",
                "Road Traffic Accident",
              ].map((text, index) => (
                <div className="button" key={index}>
                  <button
                    onClick={() => handleButtonClick(text)}
                    className={activeButton === text ? "active" : ""}
                  >
                    {text}
                  </button>
                </div>
              ))}
            </div>
            <p>
              <span>
                <img
                  src={PadLock}
                  alt=""
                  width={12}
                  height={14}
                  className="pad-lock"
                />
              </span>
              <span className="safe_secure">
                Your Information is safe & secure
              </span>
            </p>
          </div>
          <div className="banner__details-review mob-show">
            <div className="review-image">
              <img src={ReviewerOne} alt="" srcSet="" width={91} height={91} />
            </div>
            <div className="review-details">
              <figure>
                <img src={FiveStar} alt="" srcSet="" />
              </figure>
              <p>
                <strong> “Excellent! Very good</strong> manners and explained
                how the situation would work. Took time and was not hurried.
                Always asked if I needed time and or help during conversation.”
              </p>
              <span>
                <span className="uName">Janet</span>
                <img src={GreenCheckMark} alt="" width={19} height={19} />
                <span>Verified customer</span>
              </span>
            </div>
          </div>
        </div>
        <div className="banner__description">
          <h3>Disclaimer</h3>
          No Win No Fee customers pay 20% of the amount recovered. No
          termination fee applies. Authorised and Regulated by The Financial
          Conduct Authority. Firm Reference Number FRN837720. Any solicitor we
          refer you to is an independent professional from whom you will receive
          impartial and confidential advice. You are free to choose another
          solicitor. We receive a payment for introducing you to our partners.
          {/* *National Injury Claimline Ltd is a claims management company that
          receives payments from our partnered law firms for our service. If you
          qualify for a free claim assessment and that assessment is successful,
          you will be connected to an expert law firm to assist with your
          potential claim. Our service is completely free. */}
        </div>
      </section>
    </div>
  );
};
export default Banner;
