import React, { Fragment } from "react";
import "./disclaimer.scss";

const Disclaimer = () => {
  return (
    <Fragment>
      <div className="disclaimer">
        <div className="container1500">
          <h2>Disclaimer :</h2>
          <div className="desc">
            <p>
              No Win No Fee customers pay 20% of the amount recovered. No
              termination fee applies. Authorised and Regulated by The Financial
              Conduct Authority. Firm Reference Number FRN837720. Any solicitor
              we refer you to is an independent professional from whom you will
              receive impartial and confidential advice. You are free to choose
              another solicitor. We receive a payment for introducing you to our
              partners.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Disclaimer;
