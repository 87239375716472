import React, { Fragment } from "react";
import rightArrow from "../../assests/images/btn-right-arrow.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./claimButton.scss";

const ClaimButton = () => {
  return (
    <Fragment>
      <button
        type="button"
        className="claimBtn"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        Start Your Claim
        <span>
          <LazyLoadImage className="arrow" src={rightArrow} alt="arrow" />
        </span>
      </button>
    </Fragment>
  );
};

export default ClaimButton;
