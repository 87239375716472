import LoriImg from "../assests/images/client-Lori.png";
import MarkKImg from "../assests/images/client-MarkK.png";
import RitaImg from "../assests/images/clientR.png";

const ClientRevData = [
  {
    id: 1,
    userName: "Jordan Banks",
    img: "",
    totalRev: "3 reviews",
    date: "May 31, 2024",
    bodyTitle: "Claims solution scotland were superb",
    bodyDesc:
      "Claims solution scotland were superb, handled my claim quickly and efficiently, they passed it to the right people and I was delighted with the outcome ",
    expDate: "August 28, 2023",
    rating: 5,
  },
  {
    id: 2,
    userName: "Rebecca",
    img: "",
    totalRev: "2 reviews",
    date: "Jan 5, 2024",
    bodyTitle: "Amazing from start to finish",
    bodyDesc:
      "Amazing from start to finish. Was involved in a car accident where the other driver left the scene, could not thank John & all the team enough for their help. From immediately getting me a replacement car and getting mine fixed to helping out with the personal injury claim it just completely took any stress and hassle out of it. Would 100% recommend ☺️! Thanks again guys!",
    expDate: "January 04, 2024",
    rating: 5,
  },
  {
    id: 3,
    userName: "Shannon Osprey",
    img: "",
    totalRev: "1 reviews",
    date: "Jun 8, 2024",
    bodyTitle: "they were really supportive and very…",
    bodyDesc: "they were really supportive and very helpful ",
    expDate: "August 28, 2023",
    rating: 5,
  },
  {
    id: 4,
    userName: "Tim S",
    img: "",
    totalRev: "1 reviews",
    date: "Nov 3, 2020",
    bodyTitle: "Great firm, great guys. Very happy to recommend.",
    bodyDesc:
      "Great firm. Great guys. You'll get a top-notch service from Claim Solutions Scotland. They'll take care of you, just as they've taken care of me. They're honest, hard working, professional and competent. They'll have your best interests at heart and I'm very happy to recommend them; especially John, William, Agnes & Daniel.",
    expDate: "November 02, 2020",
    rating: 5,
  },
  {
    id: 5,
    userName: "Kayleigh Ferguson",
    img: "",
    totalRev: "1 reviews",
    date: "Jan 20, 2024",
    bodyTitle: "🌟🌟🌟🌟🌟 I had a great experience with…",
    bodyDesc:
      "🌟🌟🌟🌟🌟 I had a great experience with Claims-Solutions-Scotland when I needed to file a claim after my car accident. Their customer service was top-notch, always responsive and helpful. They handled my claim quickly and efficiently, making the whole process stress-free. The repair work on my car was done flawlessly, and I was reimbursed promptly. I highly recommend Claim Solutions SCotland for their excellent service!",
    expDate: "January 19, 2024",
    rating: 5,
  },
  {
    id: 6,
    userName: "Lori Obrien ",
    img: LoriImg,
    totalRev: "14 reviews",
    date: "May 16, 2023",
    bodyTitle:
      "Nicest people ever to talk to and help you. I spoke with a man called John and he…",
    bodyDesc:
      "I spoke with a man called John and he instantly make me feel comfortable to talk to him about what had happened to me. I was jagged with a needle in my work ,John then proceeded to get to work and put my claim into the right hands , I only clicked on the link to see if I could get something for the pain and suffering I went through and I don’t think I would have done anything about what happened if it wasn’t for John 10/10 amazing company.please don’t hesitate to ask for help you will find it here. 👍🏼",
    expDate: "November 09, 2022",
    rating: 5,
  },
  {
    id: 7,
    userName: "Mark Kemp",
    img: MarkKImg,
    totalRev: "3 reviews",
    date: "Mar 28, 2020",
    bodyTitle: "Mark kemp best claims company ive dealt with",
    bodyDesc:
      "Couldnt have been easier or more pleasant from start to finish. Everything dealt with over the phone direct with john. Talked me through everything step by step and then passed me onto there partner solicitors at kerr brown who had more expertise in dealing with my injury claim. Therefore getting a more experienced decision on my i jury which led to me getting more money in compensation. Definetly recommend claims solutions scotland  And if im ever in need of help again they will be the first company i call or recommend to my family/friends to call.",
    expDate: "March 27, 2020",
    rating: 5,
  },
  {
    id: 8,
    userName: "customer",
    img: "",
    totalRev: "7 reviews",
    date: "Mar 29, 2020",
    bodyTitle: "I highly recommend Claims Solutions",
    bodyDesc:
      "I highly recommend Claims Solutions. The communication was first class, always keeping me upto date with any change in the claim & they were very approachable, answered all the questions I had & ensured that i felt comfortable asking any questions during the whole process. I was over the moon with the outcome of my claim & it was all dome pretty quickly. Thanks again .",
    expDate: "March 28, 2020",
    rating: 5,
  },
  {
    id: 9,
    userName: "Ian Irvine",
    img: "",
    totalRev: "10 reviews",
    date: "May 10, 2023",
    bodyTitle: "Great Company!",
    bodyDesc:
      "This company are always professional and helpful and they take ownership all the way through! Special mention for Louise Mee who always goes above and beyond.......lierally, nothing is too much trouble for her.......thanks Louise!",
    expDate: "May 10, 2023",
    rating: 5,
  },
  {
    id: 10,
    userName: "Agnes Devlin",
    img: "",
    totalRev: "2 reviews",
    date: "Jan 31, 2019",
    bodyTitle: "I was involved in a crash last year…",
    bodyDesc:
      "I was involved in a crash last year where a van crashed into the back of me. I wasn’t sure who to choose for my claim as there are literally 100,s of companies out there offering their services. Claims Solution were recommended through a friend. I am so happy I chose them, cannot fault their professional approach and prompt response. Nothing was a problem throughout the whole claim. I would definitely recommend Claims solution 👌",
    expDate: "January 30, 2019",
    rating: 5,
  },
  {
    id: 11,
    userName: "Rita Durham",
    img: RitaImg,
    totalRev: "3 reviews",
    date: "Feb 5, 2021",
    bodyTitle: "Fantastic service highly recommend",
    bodyDesc:
      "I didn't think about claiming however they were very efficient kept me up to date. Trust is important honest thats all you can be they did all the hard work. Im truly grateful it was all resolved quickly. Very happy customer go for it no win no fee and the fee if you win is worth it especially if you weren't going to bother proceeding",
    expDate: " February 04, 2021",
    rating: 5,
  },
];
export { ClientRevData };
