import reliefImg from "../assests/images/financialRelief.png";
import justiceImg from "../assests/images/justice.png";
import peaceOfMindImg from "../assests/images/peace-of-mind.png";

const BenifitData = [
    {
        id: 1,
        image: reliefImg,
        title: "Financial Relief",
        desc: "Fair compensation can ease your stress and lift the financial burden, giving you the relief you need."
    },
    {
        id: 2,
        image: justiceImg,
        title: "Justice and Fairness",
        desc: "Many are unaware they’re owed compensation, often shortchanged by insurance. Get what you truly deserve."
    },
    {
        id: 3,
        image: peaceOfMindImg,
        title: "Peace of Mind",
        desc: "We handle everything so you can focus on recovery."
    }
]
export { BenifitData };