import React from "react";
import "./Sorry.scss";
import useUTMParams from "../../hooks/utmParams";
import { Link } from "react-router-dom";
const Sorry = () => {
  const utmQuery = useUTMParams();
  return (
    <section className="sorry">
      <div className="container1286">
        <h1>We're Sorry!</h1>
        <h2>unfortunately, you are not qualified for this compensation.</h2>

        <div className="sorry-btns">
          <Link to={`/${utmQuery}`}>Back To Home</Link>
        </div>
      </div>
    </section>
  );
};

export default Sorry;
