import { LazyLoadImage } from "react-lazy-load-image-component";
import Starratting from "../assests/images/star-with-border.svg";

const renderStarImages = (count) => {
  return Array.from({ length: count }, (_, index) => (
    <LazyLoadImage
      key={`star-${index}`}
      src={Starratting}
      alt="Star"
      effect="blur"
    />
  ));
};

export default renderStarImages;