import React, { Fragment, useState } from "react";
import "./clientrev.scss";
import starSvg from "../../assests/images/trustpilot.svg";
import FiveStar from "../../assests/images/five-star.svg";
import locationImg from "../../assests/images/location.svg";
import { ClientRevData } from "../../utils/ClientRevData";
const ClientRev = () => {
  const [visibleCount, setVisibleCount] = useState(6);
  const handleLoadMore = () => {
    setVisibleCount(ClientRevData?.length);
  };
  return (
    <Fragment>
      <div className="clientRev">
        <div className="container">
          <h2>
            Here's what <span>our clients are saying</span>
          </h2>
          <div className="banner__details-rating">
            <span>
              <strong>Excellent</strong>
            </span>
            <span>
              <img src={FiveStar} alt="" srcSet="" />
            </span>
            <span>
              <strong>1,135+</strong> Reviews On
            </span>
            <span>
              <img src={starSvg} alt="" />
            </span>
          </div>
          <div className="revContainer">
            {ClientRevData?.slice(0, visibleCount)?.map((data) => {
              return (
                <div className="revCard" key={data?.id}>
                  <div className="revCard-header">
                    {data?.img !== "" ? (
                      <img className="userImg" src={data?.img} alt="user" />
                    ) : (
                      <div className="userNameImg">{data?.userName[0]}</div>
                    )}
                    <div className="right">
                      <h6>{data?.userName}</h6>
                      <div className="revNo">
                        <p>{data?.totalRev}</p>
                        <p>
                          <img
                            className="locationImg"
                            src={locationImg}
                            alt="location"
                          />
                          <span>GB</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="revCard-body">
                    <div className="whenRated">
                      <img
                        src={`https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${data?.rating}.svg`}
                        alt=""
                        width={90}
                      />
                      <p>{data?.date}</p>
                    </div>
                    <div className="info">
                      <h4 className="title">{data?.bodyTitle}</h4>
                      <p className="desc">{data?.bodyDesc}</p>
                      <h5 className="experiance">
                        Date of experience: <span> {data?.expDate}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="revCard-footer">
                    <div className="left">
                      <div className="useful">
                        <svg
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          className="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14px"
                          height="14px"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.94.94A1.5 1.5 0 0 1 10.5 2a20.774 20.774 0 0 1-.384 4H14.5A1.5 1.5 0 0 1 16 7.5v.066l-1.845 6.9-.094.095A1.5 1.5 0 0 1 13 15H9c-.32 0-.685-.078-1.038-.174-.357-.097-.743-.226-1.112-.349l-.008-.003c-.378-.126-.74-.246-1.067-.335C5.44 14.047 5.18 14 5 14v.941l-5 .625V6h5v.788c.913-.4 1.524-1.357 1.926-2.418A10.169 10.169 0 0 0 7.5 1.973 1.5 1.5 0 0 1 7.94.939ZM8 2l.498.045v.006l-.002.013a4.507 4.507 0 0 1-.026.217 11.166 11.166 0 0 1-.609 2.443C7.396 5.951 6.541 7.404 5 7.851V13c.32 0 .685.078 1.038.174.357.097.743.226 1.112.349l.008.003c.378.126.74.246 1.067.335.335.092.594.139.775.139h4a.5.5 0 0 0 .265-.076l1.732-6.479A.5.5 0 0 0 14.5 7H8.874l.138-.61c.326-1.44.49-2.913.488-4.39a.5.5 0 0 0-1 0v.023l-.002.022L8 2ZM4 7H1v7.434l3-.375V7Zm-1.5 5.75a.25.25 0 1 0 0-.5.25.25 0 0 0 0 .5Zm-.75-.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Z"
                          ></path>
                        </svg>
                        <p>Useful</p>
                      </div>
                      <div className="share">
                        <svg
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          className="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14px"
                          height="14px"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3 2a3 3 0 1 1 .583 1.778L5.867 7.115a3 3 0 0 1 0 1.77l4.716 2.337a3 3 0 1 1-.45.893L5.417 9.778a3 3 0 1 1 0-3.556l4.716-2.337A3.002 3.002 0 0 1 10 3ZM1 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm10 5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
                          ></path>
                        </svg>
                        <p>Share</p>
                      </div>
                    </div>
                    <div className="right">
                      <svg
                        viewBox="0 0 16 16"
                        className="icon_icon__ECGRl icon_appearance-default___4uy_"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16px"
                        height="16px"
                        data-report-icon="true"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3 .25V0H2v16h1V9.25h11.957l-4.5-4.5 4.5-4.5H3Zm0 1v7h9.543l-3.5-3.5 3.5-3.5H3Z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {visibleCount < ClientRevData.length && (
            <button className="loadMoreRevBtn" onClick={handleLoadMore}>
              Load More Reviews
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ClientRev;
