import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import StripData from "../../utils/strip-data";
const InfoStrip = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const content = (
    <div className="strip_bar ">
      <div className="wrapper container1286">
        {StripData.map((item) => (
          <div className="strip_bar-one" key={item?.id}>
            <div key={item.id}>
              <figure>
                <img src={item.image} alt="" />
              </figure>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <section>
      {isMobile ? (
        <Marquee gradient={false} speed={50}>
          {content}
        </Marquee>
      ) : (
        content
      )}
    </section>
  );
};

export default InfoStrip;
