import React, { Fragment } from "react";
import "./deserve.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ClaimButton from "../ClaimButton";
import comRight from "../../assests/images/deserve-right.png";
import FiveStar from "../../assests/images/five-star.svg";
import TrustPilot from "../../assests/images/trustpilot-white.svg";

const Deserve = () => {
  return (
    <Fragment>
      <div className="deserve">
        <div className="container1500">
          <div className="deserve-left">
            <h2>
              <span>Claim The</span> Compensation you 100% Deserve
            </h2>
            <p className="desc">
              If you have suffered because of personal injury, we're here to
              help! We'll help you access professional legal representation and
              claim you're entitled to.
            </p>
            <ClaimButton />
            <div className="banner__details-rating deservestarRating">
              <span>
                <strong>Excellent</strong>
              </span>
              <span>
                <img src={FiveStar} alt="" srcSet="" />
              </span>
              <span>
                <strong>1,135+</strong> Reviews On
              </span>
              <span>
                <img src={TrustPilot} alt="" />
              </span>
            </div>
          </div>
          <div className="deserve-right">
            <LazyLoadImage
              className="comRightImg"
              src={comRight}
              alt="deserve"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Deserve;
