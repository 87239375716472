import { useEffect, useState } from "react";

const useUTMParams = () => {
  const [utmQuery, setUtmQuery] = useState("");

  useEffect(() => {
    const getUTMParams = () => {
      const searchParams = new URLSearchParams(window.location.search);

      // Only set UTM parameters if any exist
      if (Array.from(searchParams).length > 0) {
        const utmParams = [];
        searchParams.forEach((value, key) => {
          utmParams.push({ [key]: value });
        });
        localStorage.setItem("utmParams", JSON.stringify(utmParams));

        // Generate query string from stored UTM params
        const queryString = utmParams
          .map((param) => {
            const key = Object.keys(param)[0];
            const value = param[key];
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          })
          .join("&");

        setUtmQuery(queryString ? `?${queryString}` : "");
      } else {
        // If no UTM parameters exist, check localStorage
        const storedParams = JSON.parse(
          localStorage.getItem("utmParams") || "[]"
        );
        if (storedParams.length > 0) {
          const queryString = storedParams
            .map((param) => {
              const key = Object.keys(param)[0];
              const value = param[key];
              return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            })
            .join("&");
          setUtmQuery(queryString ? `?${queryString}` : "");
        }
      }
    };

    getUTMParams();
  }, []);

  return utmQuery;
};

export default useUTMParams;
