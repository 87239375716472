import "./App.scss";
import { Outlet } from "react-router";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Disclaimer from "./components/Disclaimer";
import { ToastContainer } from "react-toastify";
import CopyRightSection from "./components/CopyRight/CopyRightSection";

function App() {
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Disclaimer />
      <Footer />
      <CopyRightSection />
      <ToastContainer />
    </div>
  );
}

export default App;
