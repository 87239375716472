import React, { Fragment } from 'react'
import Form from '../../components/Form'

const FormPage = () => {
  return (
    <Fragment>
      <Form/>
    </Fragment>
  )
}

export default FormPage
