import DollarBag from "../assests/images/money-bag.svg";
import Star from "../assests/images/whiteStar.svg";
import ShieldPlus from "../assests/images/safety-shield.svg";
import Info from "../assests/images/info.svg";

const StripData = [
  {
    id: 1,
    image: DollarBag,
    text: "No Win No Fee*",
  },
  {
    id: 2,
    image: Star,
    text: "100+ Happy Customers",
  },
  {
    id: 3,
    image: ShieldPlus,
    text: "100% Safe and Secure",
  },
  {
    id: 4,
    image: Info,
    text: "Free Information",
  },
];

export default StripData;
